/* 全局样式 */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Nunito', 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #fff5fa;
  color: #333;
  overflow-x: hidden; /* 防止水平溢出，提高移动端体验 */
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Fredoka One', cursive;
  letter-spacing: 0.5px;
  word-wrap: break-word; /* 确保标题在小屏幕上不会溢出 */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* 导航样式 */
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffee58;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 8px solid #ffffff;
  position: relative;
}

/* 添加波浪边框 */
.navigation::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'%3E%3Cpath d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z' opacity='.25' fill='%23ffffff'%3E%3C/path%3E%3Cpath d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z' opacity='.5' fill='%23ffffff'%3E%3C/path%3E%3Cpath d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  z-index: 1;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #9c27b0;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Fredoka One', cursive;
}

.logo img {
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  text-decoration: none;
  color: #9c27b0;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.3s;
  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.nav-links a:hover {
  color: #ffffff;
  background-color: #9c27b0;
  transform: scale(1.05);
}

/* 搜索栏样式 */
.search-bar {
  display: flex;
}

.search-bar input {
  padding: 0.5rem 1rem;
  border: 2px solid #f06292;
  border-radius: 20px 0 0 20px;
  width: 220px;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
}

.search-bar button {
  padding: 0.5rem 1rem;
  background-color: #f06292;
  color: white;
  border: 2px solid #f06292;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  transition: all 0.3s;
}

.search-bar button:hover {
  background-color: #ec407a;
}

/* 主内容区样式 */
.main-content {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* 页脚样式 */
.footer {
  background-color: #9c27b0;
  color: white;
  padding: 2rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: all 0.3s;
}

.footer-links a:hover {
  color: #ffee58;
  text-decoration: underline;
}

/* 游戏卡片样式 */
.game-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  border: 3px solid #fff;
}

.game-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  border-color: #f06292;
}

.game-thumbnail {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.game-card h3 {
  margin: 1rem;
  font-size: 1.2rem;
  color: #9c27b0;
  text-align: center;
}

.game-card p {
  margin: 0 1rem 1rem;
  color: #666;
  text-align: center;
}

.play-button {
  display: block;
  margin: 1rem auto;
  padding: 0.7rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 700;
  max-width: 80%;
  transition: all 0.3s;
  box-shadow: 0 3px 5px rgba(0,0,0,0.2);
}

.play-button:hover {
  background-color: #3f9c42;
  transform: scale(1.05);
}

/* 首页样式 */
.home-page h1 {
  margin-bottom: 1rem;
  color: #9c27b0;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 1rem;
}

.home-page p {
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.games-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

/* 分类页面样式 */
.category-page h1 {
  color: #9c27b0;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2.2rem;
}

.category-page p {
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1rem;
}

.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.category-card {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  color: #9c27b0;
  font-weight: 700;
  font-size: 1.2rem;
  border: 3px solid transparent;
}

.category-card:hover {
  background-color: #f8f8f8;
  transform: translateY(-8px);
  border-color: #f06292;
}

/* 游戏详情页样式 */
.game-detail-page h1 {
  margin-bottom: 1rem;
  color: #9c27b0;
  text-align: center;
  font-size: 2.2rem;
}

.game-detail-page p {
  color: #666;
  text-align: center;
  margin-bottom: 1rem;
}

.game-canvas-container {
  margin: 2rem auto;
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  border: 3px solid #f06292;
}

.color-palette {
  display: flex;
  gap: 1rem;
  margin: 0 auto 2rem;
  justify-content: center;
  max-width: 800px;
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0,0,0,0.2);
  transition: all 0.2s;
  border: 3px solid white;
}

.color:hover {
  transform: scale(1.1);
}

/* 标题样式 */
.section-title {
  font-size: 2rem;
  color: #9c27b0;
  margin: 2rem 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fredoka One', cursive;
  letter-spacing: 1px;
}

.section-title:after {
  content: "★";
  margin-left: 10px;
  color: #f06292;
  font-size: 1.8rem;
}

/* 响应式布局 - 移动端优化 */
@media (max-width: 768px) {
  .navigation {
    flex-direction: column;
    padding: 1rem;
  }
  
  .logo {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .nav-links {
    margin-bottom: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .nav-links a {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
  
  .search-bar {
    width: 100%;
  }
  
  .search-bar input {
    width: 70%;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  
  .games-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .home-page h1 {
    font-size: 2rem;
  }
  
  .home-page p {
    font-size: 1rem;
  }
  
  .main-content {
    padding: 1rem;
  }
  
  .game-card h3 {
    font-size: 1rem;
  }
  
  .game-card p {
    font-size: 0.9rem;
  }
  
  .play-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

/* 极小屏幕的额外样式 */
@media (max-width: 480px) {
  .games-container {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
  
  .game-thumbnail {
    height: 120px;
  }
  
  .home-page h1 {
    font-size: 1.8rem;
  }
}

/* 确保所有图片都能正确响应式适应 */
img {
  max-width: 100%;
  height: auto;
}
